import React from 'react';

const svgStyle = {
  height: '1em',
};

export default function ArrowLeft() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="136.318"
      height="13.784"
      viewBox="0 0 136.318 13.784"
      style={svgStyle}
    >
      <path
        id="路径_81"
        data-name="路径 81"
        d="M3241.618,3903.646s1.387,9.015-9.188,11.789h134.454"
        transform="translate(-3231.498 -3902.583)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.864"
      />
    </svg>
  );
}
