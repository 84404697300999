import clsx from 'clsx';
import {graphql, PageProps} from 'gatsby';
import React from 'react';
import dayjs from 'dayjs';
import {QRCodeSVG} from 'qrcode.react';
import DefaultLayout from '../layouts/default';
import LuArticle, {ViewModel as ArticleViewModel} from '../components/article';
import ArrowLeft from '../svgs/arrow-left';
import * as styles from './article.module.scss';
import {Col, Popover, Row, Tooltip, Typography} from 'antd';
import {StaticImage} from 'gatsby-plugin-image';
import {GAP_SIZE_3, GAP_SIZE_BASE} from '../constants';
import {useEffect} from 'react';
import ClipboardJS from 'clipboard';
import {useTranslation} from 'react-i18next';
import {Link} from 'gatsby-plugin-react-i18next';

const ArticlePage = ({data, location}: PageProps) => {
  const article = data.directus.NewsArticle[0].translations[0];
  const currentURL = location.href?.split('#')[0];
  const {t} = useTranslation('article');
  const publishDate = dayjs(data.directus.NewsArticle[0].date_created);
  const bodyData: ArticleViewModel = {
    body: [
      {
        type: 'rich-text-raw',
        html: article?.context,
      },
    ],
  };
  const title = article?.title;
  useEffect(() => {
    new ClipboardJS('#copy_url');
  }, []);
  return (
    <DefaultLayout location={location}>
      <section className={styles.articleHeader}>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.divider} />
        <div className={styles.publish}>
          {publishDate.format('YYYY-MM-DD HH:mm')}
        </div>
      </section>
      <section className={styles.article}>
        <LuArticle vm={bodyData} />
        <Row
          className={styles.share}
          justify="center"
          gutter={[GAP_SIZE_BASE, GAP_SIZE_3]}
        >
          <Col>{t('分享')}</Col>
          <Col>
            <Popover
              content={<QRCodeSVG value={currentURL} />}
              title={null}
              placement="left"
            >
              <StaticImage
                src="../images/icon-official-account.png"
                alt="公众号"
                width={36}
                placeholder="none"
                as="a"
              />
            </Popover>
          </Col>
          <Col>
            <a target="_blank" href={getWeiboShareURL(title, currentURL)}>
              <StaticImage
                width={36}
                placeholder="none"
                src="../images/icon-weibo.png"
                alt="微博"
              />
            </a>
          </Col>
          <Col>
            <Tooltip
              title="网址复制到剪贴板成功"
              trigger="click"
              placement="left"
            >
              <StaticImage
                width={26}
                src="../images/icon-link.svg"
                alt="链接"
                id="copy_url"
                as="a"
                placeholder="none"
                data-clipboard-text={currentURL}
              />
            </Tooltip>
          </Col>
        </Row>
      </section>
      <Row className={styles.actions} justify="space-between">
        <Col className={styles.button}>
          <Link to="/news">
            <ArrowLeft /> <span className={styles.text}>{t('回到目录')}</span>
          </Link>
        </Col>
      </Row>
    </DefaultLayout>
  );
};

export default ArticlePage;

function getWeiboShareURL(title: string, currentURL: string) {
  const prefix = 'https://service.weibo.com/share/share.php?';
  return `${prefix}url=${currentURL}&title=${title}`;
}
export const query = graphql`
  query NewsArticleQuery($id: String, $language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    directus {
      NewsArticle(filter: {id: {_eq: $id}}) {
        id
        date_created
        translations(filter: {languages_code: {code: {_eq: $language}}}) {
          title
          context
          languages_code {
            code
            name
          }
        }
      }
    }
  }
`;
